import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProgramService } from 'src/app/shared/services/program.service';
import { IndexComponent } from './index/index.component';
import { ProposalEvaluationComponent } from './proposal-evaluation/proposal-evaluation.component';
import { ProposalOverviewComponent } from './proposal-overview/proposal-overview.component';
import { SideMenubarComponent } from './side-menubar/side-menubar.component';
import { VendorQuestionsComponent } from './vendor-questions/vendor-questions.component';

const routes: Routes = [
  {
    path: ':id',
    component: IndexComponent,
    data: { breadcrumb: (data: any) => `${data?.opportunity?.opportunity_name}` },
    resolve: { opportunity: ProgramService },
    children: [
      {
        path: 'proposal-evaluation',//data: { breadcrumb: 'Get Proposal' } ,
        component: ProposalOverviewComponent,
      },
      {
        path: 'summary',//data: { breadcrumb: 'Get Proposal' } ,
        component: ProposalEvaluationComponent,
      },
      {
        path: 'vendor-questions',//data: { breadcrumb: 'Get Proposal' } ,
        component: VendorQuestionsComponent,
      },
      
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GetProposalRoutingModule { }
