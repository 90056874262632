import { V } from '@angular/cdk/keycodes';
import { ViewEncapsulation } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { DataToCsvService } from 'src/app/shared/services/data-to-csv.service';
import { GetProposalService } from 'src/app/shared/services/get-proposal.service';
import { MessageService } from 'src/app/shared/services/message.service';
var _ = require('lodash');
import * as textConfiguration from 'src/assets/static-text-configuration.json';
@Component({
  selector: 'app-proposal-evaluation',
  templateUrl: './proposal-evaluation.component.html',
  styleUrls: ['./proposal-evaluation.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class ProposalEvaluationComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  opportunityId: any;
  weightage: Number = 100;
  overallSumary = true;
  detailedSummary = false;
  selected = 'overall';
  detSummary: any[] = [];
  topScore: any;
  vendorList: any;
  vendorArr: any[] = [];
  overallSumaryData: any[] = []
  detailedSummaryData: any[] = []
  detailVendorList: any;
  ColorRandom: any;
  opportunity_data: any;
  toeSummary:any;
  toeSummaryVendorList:any;

  topSummarryValue:any=0;
  topSummarryName:any;

  overallAverageSummaryData:any[] = [];
  overallToeAverageSummaryData: any[] = [];
  breadcrumb: any;
  topScoreToe: any;



  constructor(private proposalService: GetProposalService, private dialog: MatDialog, private breadCrumService: BreadcrumbService,
    private messageService:MessageService,
    private router: Router, private route: ActivatedRoute,private toastrService: ToastrService,private csvService: DataToCsvService) { }

  ngOnInit(): void {
    this.messageService.setGnews('Opportunity Summary')
    this.route.parent?.params.subscribe((res: any) => {

      this.opportunityId = atob(res.id)
      this.getEvaluationData();
      this.getToESummaryData();
    })
    
    this.breadCrumService.breadcrumbs$.subscribe((bc: any) => {
      this.breadcrumb = bc;
    })
  }

  getName(name: any) {
    return name?.split(' ').slice(0, 2).map((n: any) => n[0]).join('');
  }


  getEvaluationData() {
    this.proposalService.getEvaluation(this.opportunityId).subscribe((res: any) => {
      let breadCrumbFirst: any = [this.breadcrumb[0]];
      let breadCrumbLast: any = [this.breadcrumb[this.breadcrumb.length - 1]];
      let breadCrumb = [
        { label: res.opportunity_data.portfolio_details[0].name, url: '/program', queryParams: {portfolio: res?.opportunity_data.portfolio_details[0]?.id} },
        { label: res.opportunity_data.portfolio_details[1].name, url: '/program', queryParams: {subportfolio: res?.opportunity_data.portfolio_details[1]?.id} },
        { label: res.opportunity_data.program?.name, url: '/program', queryParams: {program: res?.opportunity_data.program?.id} }
      ];
      breadCrumbFirst.push(...breadCrumb, ...breadCrumbLast)
  
      this.breadCrumService.setBreadcrumb(breadCrumbFirst);
      localStorage.setItem('opportunity-info', JSON.stringify({
        opportunity_name: res.opportunity_data.opportunity_name,
        opportunity_number: res.opportunity_data.opportunity_number,
        opportunity_type: res.opportunity_data.opportunity_type_details?.name,
        portfolio: res.opportunity_data.portfolio_details[0].name,
        portfolio_id: res.opportunity_data.portfolio_details[0].id,
        subportfolio: res.opportunity_data.portfolio_details[1].name,
        subportfolio_id: res.opportunity_data.portfolio_details[1].id,
        program: res.opportunity_data.program?.name,
        program_id: res.opportunity_data.program?.id,
        owner_name: res.opportunity_data.owners[0].owner_details.full_name,
        single_point_of_contact: res.opportunity_data.single_point_of_contact?.full_name,

      }));


      this.opportunity_data = res.opportunity_data;
      this.detSummary = res.detailed_summary;
      this.topScore = res.top_scorer;
      res.vendor_total_average = _.sortBy(res.vendor_total_average, 'vendor_id');
      this.overallAverageSummaryData = res.vendor_total_average;
      res.detailed_summary.forEach((resData: any) => {
        
        resData.category.vendor_total_score_by_category = _.sortBy(resData.category.vendor_total_score_by_category, 'id');
        for(let index=0;index<resData?.category?.scope?.length;index++){
          resData.category.scope[index].vendor_score = _.sortBy(resData.category.scope[index].vendor_score, 'vendor');
        } 
        this.overallSumaryData.push(resData.category);
        this.detailedSummaryData.push(resData.category);
        this.vendorArr.push(...resData.category.scope);
        this.detailVendorList = this.vendorArr.reduce((prev, curr) => curr.vendor_score);
        this.vendorList = resData.category.vendor_total_score_by_category;
      });
     
      if(this.detSummary.length == 0){
        this.callEmptyPopUp();
      }
      
      // this.vendorArr.forEach((e: any) => {
      //   e.vendor_score = _.sortBy(e.vendor_score, 'vendor');
      // })
    })
  }

  getToESummaryData(){
    this.proposalService.getToeSummary(this.opportunityId).subscribe((res: any) => {
      this.toeSummary = res?.output;
      this.toeSummary.forEach((cat: any) => {
        cat.vendor_total_score_by_category = [];
        
        cat.toes.forEach((toe: any) => {
          const lowestScore = Math.min(...toe.vendors.map((item: any) => item.AS));
          const highestScore = Math.max(...toe.vendors.map((item: any) => item.AS));

          toe.vendors.forEach((vendor: any) => {
            if(vendor.AS == lowestScore){
              vendor.low_actual_score = true;
              vendor.low_weighted_score = true
            }
  
            if(vendor.AS == highestScore){
              vendor.top_actual_score = true;
              vendor.top_weighted_score = true
            }

            let ind = cat.vendor_total_score_by_category.findIndex((e: any) => e.vendor == vendor.vendor)
            
            if(ind < 0){
              vendor.total_actual_score = vendor.AS
              vendor.total_weight_score = vendor.WS
              cat.vendor_total_score_by_category.push(vendor)
            }else{
              cat.vendor_total_score_by_category[ind].total_actual_score = cat.vendor_total_score_by_category[ind].total_actual_score + vendor.AS
              cat.vendor_total_score_by_category[ind].total_weight_score = cat.vendor_total_score_by_category[ind].total_weight_score + vendor.WS
            }
            

            let over = this.overallToeAverageSummaryData.findIndex((e: any) => e.vendor == vendor.vendor)
            if(over < 0){
              vendor.total_avg_actual = vendor.AS
              vendor.total_avg_weighted = vendor.WS
              this.overallToeAverageSummaryData.push(vendor)
            }else{
              this.overallToeAverageSummaryData[over].total_avg_actual = this.overallToeAverageSummaryData[over].total_avg_actual + vendor.AS
              this.overallToeAverageSummaryData[over].total_avg_weighted = this.overallToeAverageSummaryData[over].total_avg_weighted + vendor.WS
            }
          });
        });

        const lowestScore = Math.min(...cat.vendor_total_score_by_category.map((item: any) => item.AS));
        const highestScore = Math.max(...cat.vendor_total_score_by_category.map((item: any) => item.AS));

        cat.vendor_total_score_by_category.forEach((vd: any) => {
          vd.total_actual_score = Number((vd.total_actual_score / cat.toes.length).toFixed(2));
          vd.total_weight_score = Number((vd.total_weight_score / cat.toes.length).toFixed(2));

          if(vd.AS == lowestScore){
            vd.category_low_actual_score = true;
            vd.category_low_weighted_score = true
          }

          if(vd.AS == highestScore){
            vd.category_top_actual_score = true;
            vd.category_top_weighted_score = true
          }
        });
      });

      let totalCnt = 0;
      this.toeSummary.forEach((element: any) => {
        totalCnt = totalCnt + element.toes.length;
      });

      this.overallToeAverageSummaryData.forEach((vd: any) => {
        vd.total_avg_actual = Number((vd.total_avg_actual / totalCnt).toFixed(2));
        vd.total_avg_weighted = Number((vd.total_avg_weighted / totalCnt).toFixed(2));
      });

      const highestScorer = Math.max(...this.overallToeAverageSummaryData.map((item: any) => item.total_avg_actual));
      let topScorer = this.overallToeAverageSummaryData.find((e: any) => e.total_avg_actual == highestScorer)
      this.topScoreToe = topScorer
      
      this.toeSummaryVendorList = this.toeSummary[0]?.toes[0]?.vendors;
     

    });
  }

  getVendorAsCount(vendorASCount:any,vend:any){
    let as=0;
    let vendorLength:any = 0;
    vendorASCount.forEach((toe:any) => {
        toe.vendors.forEach((vendor:any) => {
          if(vendor.vendor == vend){
            as = as + vendor.AS;
          }
        });
      });
    return as/vendorASCount.length;
  }
  
  getVendorWSCount(vendorASCount:any,vend:any){
    let ws=0;
    let vendorLength:any = 0;
    vendorASCount.forEach((toe:any) => {
      vendorLength = toe.vendors.length;
        toe.vendors.forEach((vendor:any) => {
          if(vendor.vendor == vend){
            ws = ws + vendor.WS;
          }
        });
      });
    return ws.toFixed(2);
  }

  getTotalVendorAsCount(vend:any){
    let as=0;
    let toevendorLength = 0;
    this.toeSummary.forEach((summary:any) => {
      summary?.toes?.forEach((toe:any) => {
        toevendorLength = toevendorLength+1;
         
        toe.vendors.forEach((vendor:any) => {
          if(vendor.vendor == vend){
            as = as + vendor.AS;
          }
        });
      });
    });
    return (as/toevendorLength).toFixed(2);
  }

  getTotalVendorWSCount(vend:any){
    let ws=0;
    let toevendorLength = 0;
    this.toeSummary.forEach((summary:any) => {
      summary?.toes?.forEach((toe:any) => {
        toevendorLength = toevendorLength+1;
         
        toe.vendors.forEach((vendor:any) => {
          if(vendor.vendor == vend){
            ws = ws + vendor.WS;
          }
        });
      });
    });
    let value = (ws/this.toeSummary.length).toFixed(2)
    if(value >= this.topSummarryValue){
      this.topSummarryName = vend;
      this.topSummarryValue = value;
    }
    return value;
  }

  getTotalASCountByCategory(vend:any,toes:any){
    let as=0;
      let toevendorLength = 0;
      toes?.forEach((toe:any) => {
        toevendorLength = toevendorLength+1;
        toe.vendors.forEach((vendor:any) => {
          if(vendor.vendor == vend){
            as = as + vendor.AS;
          }
        });
      });
   return (as/toes.length).toFixed(2);
  }
  getTotalWSCountByCategory(vend:any,toes:any){
    let ws=0;
      let toevendorLength = 0;
      toes?.forEach((toe:any) => {
        toevendorLength = toevendorLength+1;
        toe.vendors.forEach((vendor:any) => {
          if(vendor.vendor == vend){
            ws = ws + vendor.WS;
          }
        });
      });
   return (ws).toFixed(2);
  }

  getColor(a: any, b:any){
    let totalSum = 0;
    // a.forEach((element: any) => {
    //   element.vendors.forEach((ven: any) => {
    //     if(ven.vendor == b){
    //       totalSum = totalSum + ven.AS
    //     }
    //   });
    // });

    // console.log(totalSum / 2)
  }

  callEmptyPopUp(){
    this.dialog.closeAll();
    const dialog = this.dialog.open(ModalComponent, { hasBackdrop: true, disableClose: true  });
    dialog.componentInstance.type = {"name":"evaluation_empty","section":"release_tracker"};
    dialog.afterClosed().subscribe(data => {
      if (!!data) {
        this.router.navigate([`getproposal/`+btoa(this.opportunityId)+`/proposal-evaluation`]);
      }
    })
  }
  
  getRoundIndex(j: any){
    return j % 2;
  }


  selectSummary(e: any) {
    if (e == 'overall') {
      this.overallSumary = true;
      this.detailedSummary = false;
    } if (e == 'detailed') {
      this.detailedSummary = true;
      this.overallSumary = false;
    }
  }

  saveAsCSV() {
    let data = this.overallSumaryData;
    if( this.detailedSummary){
      data = this.detailedSummaryData
    }
    if( this.overallSumary && data && data.length>0){
      const items:any = [];
      const vendorArray = data[0].vendor_total_score_by_category.map((el:any)=>el.name);
      let obj:any ={
        "Scope/Vendor":""
      }
      vendorArray.forEach((vendor_score:any)=>{
        obj['"'+vendor_score+'"'] = "Actual Score / Weighted Score";
      })
      items.push(obj);
      data.forEach((item:any) => {
        let csvData:any ={
          "Scope/Vendor": item.display_name,
        }
        item?.vendor_total_score_by_category.forEach((vendor_score:any)=>{
          csvData['"'+vendor_score['name']+'"'] = vendor_score['total_actual_score'] + " / " + vendor_score['total_weightage_score']
        })
        items.push(csvData);
      });
      let avgdata:any ={
        "Scope/Vendor":"Average Score"
      } 
      this.overallAverageSummaryData.forEach((vendor_score:any)=>{
        avgdata['"'+vendor_score['vendor_name']+'"'] = vendor_score['total_avg_actual'] + " / " + vendor_score['total_avg_weighted']
      })
      items.push(avgdata);
      let filename = this.overallSumary ? 'Overall_Summary_Report.CSV' :'Detailed_Summary_Report.CSV';
      this.csvService.exportToCsv(filename, items);
    }
    else if(this.detailedSummary && data && data.length>0){
      const items:any = [];
      const vendorArray = data[0].vendor_total_score_by_category.map((el:any)=>el.name);
      data.forEach((item:any) => {
        let csvData:any ={
          "Scope":item.display_name,
          "Items/Vendor":"Scope Items-Weightage"
        }
        vendorArray.forEach((vendor_score:any)=>{
          csvData['"'+vendor_score+'"'] = "Actual Score / Weighted Score";
        })
        items.push(csvData);        
        item?.scope.forEach((scope:any)=>{
          let obj:any ={
            "Scope":"",
            "Items/Vendor":scope.description +" / "+scope.weightage + "%"
          }
          scope.vendor_score.forEach((vendor_score:any)=>{
            obj['"'+vendor_score['vendor__name']+'"'] = vendor_score['score'] + " / " + vendor_score['weighted_score']
          })
          items.push(obj);        
        })
        let avgdata:any ={
          "Scope":"",
          "Items/Vendor":"100%"
        } 
        item.vendor_total_score_by_category.forEach((vendor_score:any)=>{
          avgdata['"'+vendor_score['name']+'"'] = vendor_score['total_actual_score'] + " / " + vendor_score['total_weightage_score']
        })
        items.push(avgdata);
      });
      
      let filename = this.overallSumary ? 'Overall_Summary_Report.CSV' :'Detailed_Summary_Report.CSV';
      this.csvService.exportToCsv(filename, items);
    }
    else{
      this.toastrService.error('No Records to download');
    }
  }

  getTagObject(){
    return {
      entity_id: this.opportunityId,
      entity_type: "proposal"
    }
  }

  changeTab(event:any){}

  toggle(e: any){
    if(e.checked){
      this.selectSummary('detailed')
    }else{
      this.selectSummary('overall')
    }
  }
}