import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GetProposalService } from 'src/app/shared/services/get-proposal.service';
import { ProgramService } from 'src/app/shared/services/program.service';

import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ViewEncapsulation } from '@angular/core';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-proposal-overview',
  templateUrl: './proposal-overview.component.html',
  styleUrls: ['./proposal-overview.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class ProposalOverviewComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  overviewLists: any[] = [];
  opportunityId: any;
  statusList: any[] = [];
  statusSlug: any;
  side = false;
  side1 = false;
  customStyle = {
    backgroundColor: "#f1f1fc",
    borderRadius: "50%",
    color: "#232459",
    cursor: "pointer",
    fontWeight: 'bolder',
  };
  recievedDate: any;
  selectedItem: any;
  vendorList: any[] = [];
  search = false;
  searchText = '';
  newVendorItems: any;
  empanneledVendors: any[] = [];
  removeItem: any;
  itemsPerPage: number = 10;
  page: number = 1;
  totalCount: any;
  endVal: number = 10;
  startVal: number = 0;
  selected_id = 0;
  actionmode: string = '';
  vendorID:any='';
  disableFields: boolean=false;
  opportunityStatus:any={};
  opportunity_data: any;
  breadcrumb: any;
  user: any;

  constructor(private proposalService: GetProposalService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private programService: ProgramService,
    private router: Router,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private breadCrumService: BreadcrumbService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.messageService.setGnews('Opportunity Evaluation')
    this.user = JSON.parse(localStorage.getItem('permission') || '{}');
    this.route.parent?.params.subscribe((res: any) => {
      this.opportunityId = atob(res.id)
      //this.vendorID = res.vendorID;
      this.getStatus();
      this.getOverViewList();

      this.breadCrumService.breadcrumbs$.subscribe((bc: any) => {
        this.breadcrumb = bc;
      })
    })
  }

  getOverViewList() {
    this.spinner.show();
    this.proposalService.getProposalOverview(this.opportunityId).subscribe((res: any) => {
      this.spinner.hide();
      let breadCrumbFirst: any = [this.breadcrumb[0]];
      let breadCrumbLast: any = [this.breadcrumb[this.breadcrumb.length - 1]];
      let breadCrumb = [
        { label: res.opportunity_data.portfolio_details[0].name, url: '/program', queryParams: {portfolio: res?.opportunity_data.portfolio_details[0]?.id} },
        { label: res.opportunity_data.portfolio_details[1].name, url: '/program', queryParams: {subportfolio: res?.opportunity_data.portfolio_details[1]?.id} },
        { label: res.opportunity_data.program?.name, url: '/program', queryParams: {program: res?.opportunity_data.program?.id} }
      ];
      breadCrumbFirst.push(...breadCrumb, ...breadCrumbLast)
  
      this.breadCrumService.setBreadcrumb(breadCrumbFirst);
      localStorage.setItem('opportunity-info', JSON.stringify({
        opportunity_name: res.opportunity_data.opportunity_name,
        opportunity_number: res.opportunity_data.opportunity_number,
        opportunity_type: res.opportunity_data.opportunity_type_details?.name,
        portfolio: res.opportunity_data.portfolio_details[0].name,
        portfolio_id: res.opportunity_data.portfolio_details[0].id,
        subportfolio: res.opportunity_data.portfolio_details[1].name,
        subportfolio_id: res.opportunity_data.portfolio_details[1].id,
        program: res.opportunity_data.program?.name,
        program_id: res.opportunity_data.program?.id,
        owner_name: res.opportunity_data.owners[0].owner_details.full_name,
        single_point_of_contact: res.opportunity_data.single_point_of_contact?.full_name,

      }));

      this.opportunity_data = res.opportunity_data;
      let sorted = res.records.sort((a: any,b: any) => a.vendor_details.name > b.vendor_details.name ? 1 : -1)
      this.overviewLists = sorted;
      this.opportunityStatus = res;
      this.programService.getOppStatus(this.opportunityId).subscribe((res:any)=>{ 
        if(res.status.slug == 'opportunity_sow_signed' || res.status.slug == 'opportunity_cancelled'){
          this.disableFields = true;
        }
      })
      this.getVendorList();
      (this.overviewLists || []).map((i: any) => {
        this.empanneledVendors.push(i.vendor);
      //  i.last_updated_on = !!i.last_updated_on ? moment(i.last_updated_on).format('DD-MM-yyyy  HH:mm') : '';
        i.received_date = moment(i.received_date).utcOffset(0).toISOString(),
          this.statusList.forEach(element => {
            if (element.action_complete_label == i.status) {
              i.updatedStatus = element;
            }
          });
        return i;
      }, (error: any) => {
        this.spinner.hide();
        throw error;
      })
    })
  }

  getVendorList() {
    this.programService.getOpportunityFormData('opportunity_empanelled_vendors').subscribe((res: any) => {
      this.vendorList = res.records[0].field_values;
      this.overviewLists.forEach((element: any) => {
        this.vendorList.forEach((e: any) => {
          if (element.vendor == e.id) {
            const temparr = e;
            this.vendorList = this.vendorList.filter((e: any) => e.id !== element.vendor);
          }
        });
      });
      this.newVendorItems = JSON.stringify(this.vendorList);
    }, error => {
      throw error;
    })
  }

  addVendor(item: any) {

    this.overviewLists.push({
      vendor: item.id
    });
    this.updateVendors();
    this.vendorList = this.vendorList.filter((e: any) => item.id != e.id);

  }

  getStatus() {
    this.proposalService.getStatus().subscribe((res: any) => {
      this.statusList = res;
      this.getOverViewList();

    }, error => {
      throw error;
    })
  }
  onDateChange(event: any, i: any) {
    console.log('event', event);
    if(!this.disableFields){
      this.selectedItem = i.vendor_details.id;
      this.recievedDate = event.value ? moment(event.value).format('yyyy-MM-DD') : '';
      this.updateReceivedDate();
    }
  }

  selectStatus(e: any, i: any) {
    console.log('e', e);
    this.statusSlug = e.value.slug;
    this.selectedItem = i.vendor_details.id;
    this.updateStatus();
  }

  updateStatus() {
    const payload = {
      opportunity: parseInt(this.opportunityId),
      status: this.statusSlug,
      vendor: this.selectedItem
    }
    this.proposalService.updateStatus(payload).subscribe((res: any) => {
      this.getOverViewList();
    }, error => {
      throw error;
    })
  }

  updateReceivedDate() {
    const payload = {
      opportunity: parseInt(this.opportunityId),
      vendor: this.selectedItem,
      received_date: this.recievedDate
    }
    this.proposalService.updateReceivedDate(payload).subscribe((res: any) => {
    }, error => {
      throw error;
    })
  }



  onInputVendorChange(e: any) {
    this.searchText = e.target.value;
    const items = JSON.parse(this.newVendorItems);
    if (!!this.searchText) {
      this.search = true;
      this.searchText = this.searchText.toLowerCase();
      setTimeout(() => {
        this.vendorList = items.filter((e: any) => e.name.toLowerCase().includes(this.searchText) | e.team.toLowerCase().includes(this.searchText));

      }, 200);
    } else {
      this.search = false;
      this.vendorList = items;
    }
  }

  viewDocument(item: any) {
    console.log('view item', item);
    this.router.navigate([`vendor/${btoa(this.opportunityId)}/${item}/proposal-details`]);
  }

  updateVendors() {
    let payload: any = {};
    const vendors_values = this.overviewLists.map((item: any) => {
      return { vendor: item.vendor };
    })
    payload.empanelled_vendors = vendors_values;
    this.programService.updateOpportunity(this.opportunityId, payload).subscribe((res: any) => {
      this.getOverViewList();
    }, error => {
      throw error;
    })
  }

  getEvaluationData(item:any, content: any,content1:any) {
    this.proposalService.getEvaluation(this.opportunityId).subscribe((res: any) => {
      let selectedVendor =res.vendor_total_average?.find((o:any)=>o.vendor_id === item.vendor);
      if(selectedVendor && selectedVendor.total_avg_weighted){       
        this.dialog.open(content1);
        console.log('item', item);
      }else{
        this.dialog.open(content);         
        this.removeItem = item;       
      }      
    },err=>{this.toastr.error(err.message)})
  }
  deleteDialog() {
    this.overviewLists = this.overviewLists.filter((i: any) => i.vendor != this.removeItem.vendor);
    this.updateVendors();
    this.dialog.closeAll();
    this.toastr.success('Removed Successfully');
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  onChangeTable(page: any) {
    this.page = page;
    this.startVal = (page - 1) * (this.itemsPerPage);
    this.endVal = this.startVal + (this.itemsPerPage);
    this.getOverViewList()

  }

  getShortName(fullName: any) {
    return fullName.split(' ').map((n: any) => n[0]).join('');
  }

  getName(name: any) {
    return name ? name.split(' ').slice(0, 2).map((n: any) => n[0]).join('') : name;
  }
  changeCount(e: any) {
    this.actionmode = '';
  }

  addform() {
    console.log('item',);
    this.selected_id = 0;
    this.actionmode = 'add';
  }
  callRefereshParentData(e: any) {
    this.actionmode = '';
    this.getOverViewList();
  }
  
  hideAccordion=(index:number, proceed:string)=>{
    this.overviewLists[index]['show']=false;
    if(proceed=='next'){
      if(this.overviewLists.length> (index+1)){
        this.overviewLists[index+1]['show']=true;
      }
    }
  }


  getTagObject(){
    return {
      entity_id: this.opportunityId,
      entity_type: "proposal"
    }
  }
}
