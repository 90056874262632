<!-- <div class="w-100 bg-lavendar mt-80">
    <app-header></app-header>
</div>
<div class="container-fluid container-xxl">
    <div class="row w-100 m-0">
        <div class="col-md-3 mat-style p-0 mt-4">
            <app-left-side-menu></app-left-side-menu>
        </div>
        <div class="col-md-9 rigth-col mt-4">
            <router-outlet></router-outlet>
        </div>
    </div>
</div> -->
<div class="w-100 bg-lavendar mt-80">
    <app-header></app-header>
</div>
<div class="p-3">
    <div class="px-0 pb-4">
        <app-bread-crumb></app-bread-crumb>
    </div>
    <div class="row">
        <div class="col-sm-2">
            <div class="single-left-pane">
                <app-left-side-menu></app-left-side-menu>
            </div>
        </div>
        <div class="col-sm-7">
            <div class="single-center-pane">
                <router-outlet></router-outlet>
            </div>
        </div>
        <div class="col-sm-3">
            <div class="single-right-pane">
                <span class="bubble-top" style="background-color: #ED0000;">&nbsp;</span>
                <app-tags-mapped-projects></app-tags-mapped-projects>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>