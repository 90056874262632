<mat-drawer-container autosize class="p-0">
    <div class="rightpanel pr-0">
        <div class="centerpanel pr-0">
            <div class="custom-height pr-0" id="custom-height">
                        <div class="row mb-2">
                            <div class="col-md-12">
                                <div>
                                    <p class="fw-bold fs-24 mb-0">{{!user?.is_vendor ? staticText?.proposal?.evaluation : staticText?.proposal?.evaluate_vendor }}</p>
                                    <p class="m-0 mb-1" style="font-size: 13px;">{{opportunity_data?.opportunity_name}} | {{opportunity_data?.opportunity_number}} | {{opportunity_data?.opportunity_type_details?.name}} | {{opportunity_data?.portfolio_details[0].name}} | {{opportunity_data?.portfolio_details[1].name}}
                                        | {{opportunity_data?.program?.name}} | {{opportunity_data?.owners[0].owner_details.full_name}} | {{opportunity_data?.single_point_of_contact?.full_name}}
                                    </p>
                                    <app-tag [dataObj]="getTagObject(opportunity_data?.id)" [tagsIds]="opportunity_data?.tag_ids" *ngIf="opportunity_data"></app-tag> 
                                </div>
                            </div>
                            <!-- <div class="col-md-4" *ngIf="!disableFields">
                                <button mat-button class="float-end border primary lightSecondary add-vendor-btn  ml-3"
                                    mat-flat-button (click)="drawer.toggle()">

                                    <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                        class="cursor-pointer fs-5">add
                                    </mat-icon>
                                    {{staticText?.proposal?.proposal_overview.add_dp}}
                                </button>
                            </div> -->
                        </div>
                        <div class="accordion scroll" id="accordionExample">
                            <div class="d-flex justify-content-between"  *ngFor="let i of overviewLists | paginate: { itemsPerPage: 10, currentPage: page, totalItems: totalCount } ; let e = index" >
                                <div class="accordion-item mb-2 p-0">
                                    <div class="accordion-header" id="headingOne{{e}}" (click)="i['show']=true;">
                                        <!-- <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseOne'+e" > -->
                                            <div class="col-md-auto card-col p-0 position-relative w-100 accordion-button" [ngClass]="{'collapsed':!i['show']}"  data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseOne'+e" >
                                                <div class="row grid col-12 m-0 pt-2 pb-1 justify-content-between cursor-pointer" >
                                                    <div class="col-md-8">
                                                        <div class="pl-2 d-flex justify-content-start">
                                                            <div class="mr-2">
                                                                <span class="name-1"
                                                                    [ngStyle]="{'backgroundColor':e|getRandomColor}">{{getName(i.vendor_details?.name)}}</span>
                                                            </div>
                                                            <div>
                                                                <div class="mt-2">
                                                                    <span class="company-name"
                                                                        matTooltip="{{i.vendor_details?.name}}"  matTooltipClass="example-tooltip">
                                                                        {{i.vendor_details?.name}}</span>
                                                                </div>
                                                                <!-- <div class="d-flex">
                                                                    <p class="p-0 m-0 team text-dark">{{i.vendor_details?.team}}
                                                                    </p>
                                                                </div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-md-3 px-2">
                                                        <div class="pl-2 d-flex justify-content-between">
                                                            <div class="d-flex">
                                                                <div class="mr-3"
                                                                    *ngIf="i.vendor_details?.primary_contact_details?.full_name">
                                                                    <span class="name-1"
                                                                        [ngStyle]="{'backgroundColor': (e|getRandomColor:'chart').bgColor}">{{getName(i.vendor_details?.primary_contact_details?.full_name)}}</span>
                                                                </div>
                                                                <div>
                                                                    <div class="d-flex score-count">
                                                                        <p class="vendor-name mt-2"
                                                                            matTooltip="{{i.vendor_details?.primary_contact_details?.full_name}}"  matTooltipClass="example-tooltip">
                                                                            {{i.vendor_details?.primary_contact_details?.full_name |
                                                                            titlecase}}</p>
                                                                    </div>
                                                                    <br>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                    <div class="col-md-4 ml-auto px-2 " style="justify-content: right; display: flex;" *ngIf="!user?.is_vendor">
                                                        <div class="d-flex mt-3 datepicker-style" (click)="$event.preventDefault();$event.stopPropagation();" *ngIf="i.received_date">
                                                            <mat-datepicker-toggle matprefix [for]="picker">
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #picker>
                                                            </mat-datepicker>
                                                            <input matInput [matDatepicker]="picker" readonly status="basic"
                                                                 class="py-0 cursor-pointer" disabled="true"
                                                                (dateChange)="onDateChange($event, i)" (click)="$event.preventDefault();$event.stopPropagation();picker.open()"
                                                                [(ngModel)]="i.received_date" class="text-grey mt-1">
            
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="last-modified" *ngIf="i?.last_updated_on"
                                                    matTooltip="Last Updated by: {{i?.last_updated_by?.full_name}}"  matTooltipClass="example-tooltip">
                                                    <p>{{staticText?.common.last_updated}} {{i?.last_updated_on | dateAgo}}
                                                </div>
                                            </div>
                                        <!-- </button> -->
                                    </div>
                                    <div id="collapseOne{{e}}" class="accordion-collapse" data-bs-parent="#accordionExample" [ngClass]="i['show']?'show':'collapse'" >
                                        <div class="accordion-body" *ngIf="i['show']">
                                        <!-- <app-toe-file-upload [toeId]="opportunityId" [opportunityId]="opportunityId" [vendorID]="i.vendor"></app-toe-file-upload> -->
                                        <app-proposal-index [opportunityId]="opportunityId" (onClose)="hideAccordion(e, $event);" [receivedDate]="i.received_date" [vendorID]="i.vendor" [vendorStatus]="i.status"></app-proposal-index>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="p-0 cancel-icon" *ngIf="!disableFields && !user?.is_vendor">
                                    <mat-icon
                                        class="cursor-pointer delete-card material-icons mat-icon-delete fs-5 mt-2"
                                        (click)="getEvaluationData(i, delete,cannotdelete)">cancel
                                    </mat-icon>
                                </div>                           -->
                            </div>
                            <div class="row" *ngIf="overviewLists?.length == 0">
                                <div class="col-md-5 mx-auto">
                                    <img src="./assets/images/no-record.png" class="noFound" height="100%"
                                        width="100%">
                                </div>
                                <div class="col-md-8 mx-auto">
                                    <p class="text-center fw-bold text-muted"> {{staticText.common.no_records_found}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="scroll" id="accordionExample">
                            <div class="d-flex justify-content-between align-items-center"
                                *ngFor="let i of overviewLists | paginate: { itemsPerPage: 10, currentPage: page, totalItems: totalCount } ; let e = index" 
                                >
                                <div class="col-md-auto card-col p-0 mb-2 position-relative" data-bs-toggle="collapse" data-bs-target="#collapseOne{{e}}" aria-expanded="true" aria-controls="collapseOne">
                                    <div class="row grid m-0 pb-1 justify-content-between card-grid cursor-pointer" >
                                        <div class="col-md-4">
                                            <div class="pl-2 d-flex justify-content-between">
                                                <div class="d-flex" >
                                                    <div class="mr-3 mb-2">
                                                        <span class="name-1"
                                                            [ngStyle]="{'backgroundColor':e|getRandomColor}">{{getName(i.vendor_details?.name)}}</span>
                                                    </div>
                                                    <div>
                                                        <div class="d-flex score-count">
                                                            <p class="company-name"
                                                                matTooltip="{{i.vendor_details?.name}}"  matTooltipClass="example-tooltip">
                                                                {{i.vendor_details?.name | titlecase}}</p>
                                                        </div>
                                                        <br>
                                                        <div class="d-flex">
                                                            <p class="p-0 m-0 team text-dark">{{i.vendor_details?.team}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3 px-2">
                                            <div class="pl-2 d-flex justify-content-between">
                                                <div class="d-flex">
                                                    <div class="mr-3"
                                                        *ngIf="i.vendor_details?.primary_contact_details?.full_name">
                                                        <span class="name-1"
                                                            [ngStyle]="{'backgroundColor': (e|getRandomColor:'chart').bgColor}">{{getName(i.vendor_details?.primary_contact_details?.full_name)}}</span>
                                                    </div>
                                                    <div>
                                                        <div class="d-flex score-count">
                                                            <p class="vendor-name mt-2"
                                                                matTooltip="{{i.vendor_details?.primary_contact_details?.full_name}}"  matTooltipClass="example-tooltip">
                                                                {{i.vendor_details?.primary_contact_details?.full_name |
                                                                titlecase}}</p>
                                                        </div>
                                                        <br>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2 px-2">
                                            <div class="d-flex mt-1 datepicker-style">
                                                <mat-datepicker-toggle matprefix [for]="picker">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker>
                                                </mat-datepicker>
                                                <input matInput [matDatepicker]="picker" readonly status="basic"
                                                    placeholder="SELECT" class="py-0"
                                                    (dateChange)="onDateChange($event, i)" (click)="picker.open()"
                                                    [(ngModel)]="i.received_date" class="text-grey mt-1">

                                            </div>
                                        </div>
                                    </div>
                                    <div class="last-modified"
                                        matTooltip="Last Updated by: {{i?.last_updated_by?.full_name}}"  matTooltipClass="example-tooltip">
                                        <p>{{staticText?.common.last_updated}} {{i?.last_updated_on | dateAgo}}
                                    </div>
                                </div>
                                <div class="col-md-auto p-0" >
                                    <mat-icon
                                        class="cursor-pointer delete-card material-icons mat-icon-delete fs-5 mt-2"
                                        (click)="getEvaluationData(i, delete,cannotdelete)">cancel
                                    </mat-icon>
                                </div>
                            </div>

                            <div class="row" *ngIf="overviewLists?.length == 0">
                                <div class="col-md-5 mx-auto">
                                    <img src="../../../../../assets/images/no-record.png" class="noFound" height="100%"
                                        width="100%">
                                </div>
                                <div class="col-md-8 mx-auto">
                                    <p class="text-center fw-bold text-muted"> {{staticText.common.no_records_found}}
                                    </p>
                                </div>
                            </div>
                        </div> -->
                        

                        <div class="row" *ngIf="overviewLists?.length != 0" style="min-height: 2rem;">
                            <div class="row p-0 ">
                                <div class=" d-flex justify-content-end align-items-center ">
                                    <div classs=" d-flex" style="padding: 0.8rem  2.2rem  0rem 0.1rem;">
                                        <pagination-controls nextLabel="" previousLabel=""
                                            (pageChange)="onChangeTable($event)">
                                        </pagination-controls>
                                    </div>
                                </div>
                            </div>
                        </div>
                
            </div>
        </div>
    </div>
    <ng-template #delete>
        <div class="modal-dialog custom-width m-0">
            <div class="modal-content">
                <app-modal-header-common [title]=""> </app-modal-header-common>

                <div class="modal-body">
                    <p class=" fs-6 py-3 text-center primary m-0 fw-bold">{{staticText?.modal_popup?.del_msg}}</p>
                    <p class="text-grey">{{staticText?.modal_popup?.cant_retrieve}}</p>
                </div>
                <div class="modal-footer border-0">
                    <div class="row">
                        <div class="col-md-6">
                            <button mat-button mat-dialog-close (click)="deleteDialog()" [disabled]="disableFields"
                                class="btn btn-outline-danger">{{staticText?.buttons?.delete_btn}}</button>
                        </div>
                        <div class="col-md-6">
                            <button mat-button mat-dialog-close (click)="closeDialog()"
                                class="bg-primary text-white">{{staticText?.buttons?.cancel_btn}}</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #cannotdelete>
        <div class="modal-dialog custom-width m-0">
            <div class="modal-content">
                <app-modal-header-common [title]=""> </app-modal-header-common>
            
                <div class="modal-body">
                    <p class=" fs-6 py-3 text-center primary m-0 fw-bold">{{staticText?.modal_popup?.cannot_del_msg}}</p>
                </div>
                <div class="modal-footer border-0">
                    <div class="row">
                        <div class="col-md-6">
                            <button mat-button mat-dialog-close (click)="closeDialog()"
                                class="bg-primary text-white">{{staticText?.buttons?.close_btn}}</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <mat-drawer #drawer position="end" class="example-sidenav drawer">
        <div class="col-md-auto">
            <span>
                <div class="d-flex justify-content-between align-items-center mt-2">
                    <div class=" mt-2">
                        <p class="text-grey fw-bold m-0 pl-2">{{staticText?.proposal?.proposal_overview?.add_dp | uppercase}}</p>
                    </div>
                    <!-- <div class="col-md-5"></div> -->
                    <div class=" mt-2">
                        <i class="far fa-times-circle text-grey cursor-pointer float-end fs-5"
                            (click)="drawer.close()"></i>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 px-2">
                        <mat-form-field appearance="outline" class="mat-width m-1 search-field" id="searchInput">
                            <input type="text" matInput class="w-100" placeholder="Search Members Here"
                                name="searchText" (input)="onInputVendorChange($event)">
                            <mat-icon matPrefix class="muted iconPos  cursor-pointer">search</mat-icon>
                        </mat-form-field>
                    </div>
                </div>
                <div class="d-flex">
                    <mat-list class="">
                        <mat-list-item *ngFor="let i of vendorList;let e=index;" class="my-2 matlist-item rounded ">
                            <div class="col-md-2 mb-5">
                                <span class=" name-1 vendors-avatar"
                                    [ngStyle]="{'backgroundColor': e|getRandomColor}">{{getName(i.name)}}</span>
                                <!--[ngClass]="{'titlecase-7':  i.name.includes('H'), 'titlecase-1':  i.name.includes('E'), 'titlecase-3':  i.name.includes('R'), 'titlecase-6': i.name.includes('A'), 'titlecase-5': i.name.includes('c') , 'titlecase-2': i.name.includes('k') }"-->

                            </div>
                            <div class="col-md-9 pb-2 mt-2">
                                <p class="m-0 fw-bold text-truncate fs-small" style="max-width:290px;"
                                    matTooltip="{{i.name}}"  matTooltipClass="example-tooltip">
                                    {{
                                    i.name }}</p>
                                <p class="text-muted text-truncate mt-1">
                                    {{ i.team }}
                                </p>
                                <div class="row">
                                    <div class="col-md-2 d-flex">
                                        <mat-icon class="text-warning cursor-pointer fs-5">
                                            star</mat-icon>
                                        <p class="m-0 fw-bold px-2 pb-1">{{i.rating}}</p>
                                    </div>
                                    <div class="col-md-6 d-flex pe-0">
                                        <mat-icon class="text-secondary cursor-pointer fs-5">
                                            book</mat-icon>
                                        <p class="m-0 px-2 pb-1 text-muted text-nowrap">{{i.project_count}} Projects</p>
                                    </div>
                                    <div class="col-md-4 d-flex">
                                        <mat-icon class="text-secondary cursor-pointer fs-5">
                                            person</mat-icon>
                                        <p class="m-0 m-0 fw-bold px-2 pb-1">Profile</p>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-1 mx-1 mb-5 pb-2">
                                <p class="text-color cursor-pointer" (click)="addVendor(i)"><i
                                        class="fal fa-plus fw-bold"></i></p>
                            </div>
                        </mat-list-item>
                    </mat-list>
                </div>

                <div class="text-center" *ngIf="!vendorList?.length">
                    <p class="my-2">{{staticText.common.no_records_found}}</p>
                </div>
                <!--  <div class="text-center" *ngIf="!vendorList?.length">
                    <p class="my-2"> {{staticText.common.no_records_found}}</p>
                </div> -->
            </span>
        </div>
    </mat-drawer>
</mat-drawer-container>