import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { GetProposalRoutingModule } from './get-proposal-routing.module';
import { ProposalOverviewComponent } from './proposal-overview/proposal-overview.component';
import { SideMenubarComponent } from './side-menubar/side-menubar.component';
import { IndexComponent } from './index/index.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AvatarModule } from 'ngx-avatar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProposalEvaluationComponent } from './proposal-evaluation/proposal-evaluation.component';
import { MatMenuModule } from '@angular/material/menu';
import { VendorQuestionsComponent } from './vendor-questions/vendor-questions.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxPaginationModule } from 'ngx-pagination';
import { PipeModule } from 'src/app/shared/pipes/pipe.module';
import { ToeUploadModule } from '../toe-upload/toe-upload.module';
import { VendorModule } from '../vendor/vendor.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggle, MatSlideToggleModule } from '@angular/material/slide-toggle';
/* import { ProposalAddComponent } from './proposal-overview/proposal-add/proposal-add.component'; */

@NgModule({
  declarations: [
    ProposalOverviewComponent,
    SideMenubarComponent,
    IndexComponent,
    ProposalEvaluationComponent,
    VendorQuestionsComponent,
    /* ProposalAddComponent, */
  ],
  imports: [
    CommonModule,
    GetProposalRoutingModule,
    SharedModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    AvatarModule,
    MatExpansionModule,
    MatSidenavModule,
    MatListModule,
    MatTooltipModule,
    MatMenuModule,
    NgxPermissionsModule,
    NgxPaginationModule,
    PipeModule,
    ToeUploadModule,
    VendorModule,
    MatTabsModule,
    MatSlideToggleModule
  ],
  providers: [DatePipe]
})
export class GetProposalModule { }
