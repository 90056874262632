import { ViewEncapsulation } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
@Component({
  selector: 'app-side-menubar',
  templateUrl: './side-menubar.component.html',
  styleUrls: ['./side-menubar.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class SideMenubarComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  panelOpenState = true;
  projectId: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute

  ) { }

  ngOnInit(): void {
    console.log('route', this.route)
    this.route.params.subscribe((res: any) => {
      this.projectId = atob(res.id);
    })
  }
  getPrimaryAddress() {
    this.router.navigate([`getproposal/${btoa(this.projectId)}/proposal-evaluation`])
  }

  getEvaluation() {
    this.router.navigate([`getproposal/${btoa(this.projectId)}/summary`])
  }


  overview(data: any) {
    /*  if (this.projectId != '' || this.projectId != undefined) {
       console.log("project id overview", this.projectId);
       console.log('data overview', data); */
    this.router.navigate([`view/${btoa(this.projectId)}/overview/`])

  }

  resource() {
    /*  if (this.projectId != '' || this.projectId != undefined) {
       console.log("project id resources", this.projectId);
       console.log('data overview'); */
    this.router.navigate([`view/${btoa(this.projectId)}/resource/`])
    /// }
  }

  scorecard() {
    this.router.navigate([`view/${btoa(this.projectId)}/scorecard/`])
  }

  clickDelivery() {
    this.router.navigate([`getproposal/${btoa(this.projectId)}/summary`])
  }

  // vendorQuestions() {
  //   this.router.navigate([`getproposal/${btoa(this.projectId)}/vendor-questions`])

  // }

}
