<div class="mat-style d-flex flex-column mt-5 ml-2">
    <div class="mb-1">
        <mat-accordion>
            <!--  <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title class="text-uppercase font-style">
                        Get Proposals
                    </mat-panel-title>
                </mat-expansion-panel-header>

            </mat-expansion-panel>
 -->

            <mat-expansion-panel class="mat-style">
                <mat-expansion-panel-header class="panel-style" >
                    <mat-panel-title class="text-uppercase font-style" style="color: white;">
                        {{staticText?.proposal.side_bar.get_proposal}} 
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <li class="list-group-item bg-light bg-opacity-10 text-uppercase font-style py-3 d-flex ps-0 cursor-pointer"
                    (click)="getPrimaryAddress()">
                    <mat-icon aria-label="material-icons-filled" class="cursor-pointer fs-5">chevron_right
                    </mat-icon>{{staticText?.proposal.proposal_overview.title}} 
                </li>
                <li class="list-group-item bg-light bg-opacity-10 text-uppercase font-style d-flex ps-0 cursor-pointer"
                (click)="getEvaluation()">
                    <mat-icon aria-label="material-icons-filled" class="cursor-pointer fs-5">chevron_right
                    </mat-icon>{{staticText?.proposal.proposal_evaluation.title}} 
                </li> 
                <li 
                    class="list-group-item bg-light bg-opacity-10 text-uppercase font-style py-3 d-flex ps-0 cursor-pointer">
                    <mat-icon aria-label="material-icons-filled" class="cursor-pointer fs-5">chevron_right
                    </mat-icon>{{staticText?.proposal.side_bar.vendor_qns}} 
                </li>
            </mat-expansion-panel>
            <mat-expansion-panel class="mat-style">
                <mat-expansion-panel-header class="panel-style">
                    <mat-panel-title class="text-uppercase font-style" style="color: white;">
                        {{staticText?.delivery.left_bar.project_setup}} 
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <li class="list-group-item bg-light bg-opacity-10 text-uppercase font-style py-3 d-flex ps-0 cursor-pointer"
                    (click)="overview('test')">
                    <mat-icon aria-label="material-icons-filled " class="cursor-pointer fs-5">chevron_right
                    </mat-icon> {{staticText?.delivery.left_bar.overview}} 
                </li>
                <li class="list-group-item bg-light bg-opacity-10 text-uppercase font-style d-flex ps-0 cursor-pointer"
                    (click)="resource()">
                    <mat-icon aria-label="material-icons-filled " class="cursor-pointer fs-5">chevron_right
                    </mat-icon>{{staticText?.delivery.left_bar.resources}} 
                </li>
                <li class="list-group-item bg-light bg-opacity-10 text-uppercase font-style py-3 d-flex ps-0 cursor-pointer"
                    (click)="scorecard()">
                    <mat-icon aria-label="material-icons-filled " class="cursor-pointer fs-5">chevron_right
                    </mat-icon>{{staticText?.delivery.left_bar.score_setup}} 
                </li>
                <!--   <mat-expansion-panel>
                    
                    <mat-expansion-panel-header>
                        <mat-panel-title class="text-uppercase text-color font-style" (click)="overview('test')"> 
                            overview
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    
                    <mat-expansion-panel-header>
                        <mat-panel-title class="text-uppercase text-color font-style" (click)="resource()">
                            resources
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title class="text-uppercase text-color font-style" (click)="scorecard()">
                            score setup
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title class="text-uppercase text-color font-style">
                            data credientials
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title class="text-uppercase text-color font-style">
                            ticket
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                </mat-expansion-panel> -->
            </mat-expansion-panel>

            <mat-expansion-panel class="mat-style">
                <mat-expansion-panel-header  class="panel-style">
                    <mat-panel-title class="text-uppercase font-style" style="color: white;" >
                        {{staticText?.toe.title}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <li class="list-group-item bg-light bg-opacity-10 text-uppercase font-style py-3 d-flex ps-0 cursor-pointer" (click)="clickDelivery">
                    <mat-icon aria-label="material-icons-filled" class="cursor-pointer fs-5">chevron_right
                    </mat-icon>{{staticText?.delivery.left_bar.dp_approval}}
                </li>
                <li class="list-group-item bg-light bg-opacity-10 text-uppercase font-style d-flex ps-0 cursor-pointer">
                    <mat-icon aria-label="material-icons-filled" class="cursor-pointer fs-5">chevron_right
                    </mat-icon>{{staticText?.delivery.left_bar.dp_contracts}}
                </li> 
            </mat-expansion-panel>

            <!--     <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title class="text-uppercase font-style">
                        Terms of Engagement
                    </mat-panel-title>
                </mat-expansion-panel-header>
            </mat-expansion-panel> -->



        </mat-accordion>
    </div>
</div>