
<div *ngIf="!opportunity_data" style="height: calc(100vh - 200px);">
    <app-loading></app-loading>
</div>
<div class="rightpanel" *ngIf="opportunity_data">
    <div class="centerpanel">
        <div class="custom-height" id="custom-height">
            <div class="">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row mt-1">
                            <div class="col-md-9">
                                <div class="pt-1">
                                    <p class="fw-bold fs-24 mb-0">{{staticText?.proposal?.proposal_evaluation.summary}}</p>
                                    <p class="m-0 mb-1" style="font-size: 13px;">{{opportunity_data?.opportunity_name}} | {{opportunity_data?.opportunity_number}} | {{opportunity_data?.opportunity_type_details?.name}} | {{opportunity_data?.portfolio_details[0].name}} | {{opportunity_data?.portfolio_details[1].name}}
                                        | {{opportunity_data?.program?.name}} | {{opportunity_data?.owners[0].owner_details.full_name}} | {{opportunity_data?.single_point_of_contact?.full_name}}
                                    </p>
                                    <app-tag [dataObj]="getTagObject(opportunity_data?.id)" [tagsIds]="opportunity_data?.tag_ids" *ngIf="opportunity_data"></app-tag> 
                                </div>
                            </div>
                            <div class="d-flex col-md-3 justify-content-end">
                                <div class="" style="display: flex; align-items: center;  justify-content: center">
                                    <mat-label style="font-size: 12px; text-align: right; padding-right: 10px;">{{staticText?.proposal.proposal_evaluation.overall_summary}}</mat-label>
                                    <mat-slide-toggle (change)="toggle($event)"></mat-slide-toggle>
                                    <mat-label style="font-size: 12px; text-align: left; padding-left: 10px;">{{staticText?.proposal.proposal_evaluation.detail_summary}}</mat-label>
                                </div>


                                <!-- <button mat-icon-button color="warn" *ngIf="(overallSumary && overallSumaryData && overallSumaryData.length>0) || (detailedSummary && detailedSummaryData && detailedSummaryData.length>0)"
                                        data-toggle="tooltip" data-placement="top" title="Export as CSV"  
                                    (click)="saveAsCSV()" ><mat-icon>cloud_download</mat-icon></button> -->
                            
                                <!-- <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="selectSummary('overall')">
                                    <span>{{staticText?.proposal.proposal_evaluation.overall_summary}}</span>
                                    </button>
                                    <button mat-menu-item (click)="selectSummary('detailed')">
                                        <span>{{staticText?.proposal.proposal_evaluation.detail_summary}}</span>
                                    </button>
                                </mat-menu> -->
                            </div>
                        </div>
                    </div>
                    <div class="mt-4">    
                        <mat-tab-group mat-align-tabs="start" class=" head">
                            <mat-tab label="Scope Summary">
                                <div class="row pr-2 pl-2">
                                    <div class="col-md-12">
                                        <div class="row mt-4">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="p-0">
                                                    <h6 class="text-uppercase fw-bold m-0">
                                                        <div class="d-flex flex-column" *ngIf="overallSumary">
                                                            <span class="p-1">{{staticText?.proposal.proposal_evaluation.overall_summary}}</span>
                                                            
                                                        </div>
                                                        <div class="d-flex flex-column" *ngIf="detailedSummary">
                                                            <span class="p-1">{{staticText?.proposal.proposal_evaluation.detail_summary}} (Actual | Weighted)</span>
                                                            
                                                        </div>
                                                    </h6>
                                                </div>
                                                <div class="d-flex">
                                                    <div>
                                                        <p class="p-0 sub-title mb-0 pt-4">{{staticText?.proposal.proposal_evaluation.overall_score}}
                                                        </p>
                                                    </div>
                                                    <span class="split">|</span>
                                                    <div class="d-flex align-items-center justify-content-center ">
                                                        <div class="mr-2">
                                                            <span class="pt-3 name-1 ">
                                                                {{getName(topScore?.vendor_name)}}</span>
                                                        </div>
                                                        <div class="pt-3 pr-2">
                                                            <p class="text-truncate client-name" matTooltip={{topScore?.vendor_name}}  matTooltipClass="example-tooltip">
                                                                {{topScore?.vendor_name}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="overallSumary && detSummary.length > 0">
                                        <div class="custom-table table-responsive">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Scope</th>
                                                        <th scope="col" colspan="2" class="align-middle text-center" *ngFor="let vendors of vendorList">{{vendors.name}}</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col"></th>
                                                        <th scope="col"></th>
                                                        <th scope="col" colspan="1" class="align-middle text-center" *ngFor="let item of [].constructor(vendorList.length * 2); even as eve">{{eve ? 'Actual' : 'Weighted'}}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let overAllSum of overallSumaryData;even as eve; last as last; let i = index">
                                                        <td>{{i + 1}}</td>
                                                        <td>{{overAllSum.display_name}}</td>
                                                        <td scope="col" colspan="1" class="align-middle text-center" *ngFor="let item of [].constructor(overAllSum.vendor_total_score_by_category.length * 2); 
                                                        even as eve; let j = index;" [ngStyle]="{'background-color': eve ? overAllSum.vendor_total_score_by_category[(j / 2)]?.category_top_actual_score ? 'green' : (overAllSum.vendor_total_score_by_category[(j / 2)]?.category_low_actual_score ? 'red' : '#FFBF00') : ''} ">
                                                        {{eve ? overAllSum.vendor_total_score_by_category[(j / 2)].total_actual_score : overAllSum.vendor_total_score_by_category[(j / 2) - 0.5].total_weightage_score}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr >
                                                        <td></td>
                                                        <td></td>
                                                        <td scope="col" class="align-middle text-center" colspan="1" *ngFor="let item of [].constructor(overallAverageSummaryData.length * 2); 
                                                            even as eve; let j = index;">
                                                            {{eve ? overallAverageSummaryData[(j / 2)].total_avg_actual : overallAverageSummaryData[(j / 2) - 0.5].total_avg_weighted}}
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                    <!-- detail table -->
                                    <div class="container" *ngIf="detailedSummary  && detSummary.length > 0">
                                        <div class="custom-table table-responsive" *ngFor="let detailSummary of detailedSummaryData;">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col" colspan="3">{{detailSummary.name}}</th>
                                                        <th scope="col" colspan="2" class="align-middle text-center" *ngFor="let vendors of detailVendorList">{{vendors.vendor__name}}</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col"></th>
                                                        <th scope="col">Scope</th>
                                                        <th scope="col">Criticality</th>
                                                        <th scope="col">Weightage</th>
                                                        <th scope="col" colspan="1" class="align-middle text-center" *ngFor="let item of [].constructor(vendorList.length * 2); even as eve">{{eve ? 'Actual' : 'Weighted'}}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <!-- *ngFor="let overAllSum of overallSumaryData;even as eve; last as last; let i = index" -->
                                                    <tr  *ngFor="let detail of detailSummary.scope;even as eve; let i = index">
                                                        <td>{{i + 1}}</td>
                                                        <td>{{detail.description}}</td>
                                                        <td class="align-middle text-center"><span>
                                                            <mat-icon *ngIf="detail.criticality == 'Nice to have'" [matTooltip]="'Nice to have'">star_half</mat-icon>
                                                        	<mat-icon *ngIf="detail.criticality == 'Mandatory'" [matTooltip]="'Mandatory'">warning</mat-icon></span></td>
                                                        <td class="align-middle text-center">{{detail.weightage}} %</td>
                                                        <td scope="col" colspan="1" class="align-middle text-center" *ngFor="let item of [].constructor(detail.vendor_score.length * 2); 
                                                        even as eve; let j = index;" [ngStyle]="{'background-color': eve ? detail.vendor_score[(j / 2)]?.top_actual_score ? 'green' : (detail.vendor_score[(j / 2)]?.low_actual_score ? 'red' : '#FFBF00') : ''} ">
                                                        {{eve ? detail.vendor_score[(j / 2)].score : detail.vendor_score[(j / 2) - 0.5].weighted_score}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td class="align-middle text-center">{{detailSummary.total_weightage}} %</td>
                                                        <td scope="col" class="align-middle text-center" colspan="1" *ngFor="let item of [].constructor(detailSummary.vendor_total_score_by_category.length * 2); 
                                                            even as eve; let j = index;">
                                                            {{eve ? detailSummary.vendor_total_score_by_category[(j / 2)].total_actual_score : detailSummary.vendor_total_score_by_category[(j / 2) - 0.5].total_weightage_score}}
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab label="Terms of Engagement Summary">
                                <div class="row pr-2 pl-2" *ngIf="toeSummary">
                                    <div class="col-md-12">
                                        <div class="row mt-4">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="p-0">
                                                    <h6 class="text-uppercase fw-bold m-0">
                                                        <div class="d-flex flex-column" *ngIf="overallSumary">
                                                            <span class="p-1">{{staticText?.proposal.proposal_evaluation.overall_summary}}</span>                                                            
                                                        </div>
                                                        <div class="d-flex flex-column" *ngIf="detailedSummary">
                                                            <span class="p-1">{{staticText?.proposal.proposal_evaluation.detail_summary}} (Actual | Weighted)</span>                                                            
                                                        </div>
                                                    </h6>
                                                </div>
                                                <div class="d-flex">
                                                    <div>
                                                        <p class="p-0 sub-title mb-0 pt-4">{{staticText?.proposal.proposal_evaluation.overall_score}}
                                                        </p>
                                                    </div>
                                                    <span class="split">|</span>
                                                    <div class="d-flex align-items-center justify-content-center ">
                                                        <div class="mr-2">
                                                            <span class="pt-3 name-1 ">
                                                                {{getName(topScoreToe?.vendor)}}</span>
                                                        </div>
                                                        <div class="pt-3 pr-2">
                                                            <p class="text-truncate client-name" matTooltip={{topScoreToe?.vendor}}  matTooltipClass="example-tooltip">
                                                                {{topScoreToe?.vendor}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="overallSumary">
                                        <div class="custom-table table-responsive">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Terms of Engagement</th>
                                                        <th scope="col" colspan="2" class="align-middle text-center" *ngFor="let vendors of toeSummaryVendorList">{{vendors.vendor}}</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col"></th>
                                                        <th scope="col"></th>
                                                        <th scope="col" colspan="1" class="align-middle text-center" *ngFor="let item of [].constructor(toeSummaryVendorList.length * 2); even as eve">{{eve ? 'Actual' : 'Weighted'}}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let overAllSum of toeSummary;even as eve; last as last; let i = index">
                                                        <td>{{i + 1}}</td>
                                                        <td>{{overAllSum.category}}</td>
                                                        <td scope="col" colspan="1" class="align-middle text-center" *ngFor="let item of [].constructor(overAllSum.vendor_total_score_by_category.length * 2); 
                                                        even as eve; let j = index;" [ngStyle]="{'background-color': eve ? overAllSum.vendor_total_score_by_category[(j / 2)]?.category_top_actual_score ? 'green' : (overAllSum.vendor_total_score_by_category[(j / 2)]?.category_low_actual_score ? 'red' : '#FFBF00') : ''} ">
                                                        {{eve ? overAllSum.vendor_total_score_by_category[(j / 2)].total_actual_score : overAllSum.vendor_total_score_by_category[(j / 2) - 0.5].total_weight_score}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr >
                                                        <td></td>
                                                        <td></td>
                                                        <td scope="col" class="align-middle text-center" colspan="1" *ngFor="let item of [].constructor(overallToeAverageSummaryData.length * 2); 
                                                            even as eve; let j = index;">
                                                            {{eve ? overallToeAverageSummaryData[(j / 2)].total_avg_actual : overallToeAverageSummaryData[(j / 2) - 0.5].total_avg_weighted}}
                                                        </td>
                                                    </tr>
                                                </tfoot>


                                                <!-- <tbody>
                                                    <tr *ngFor="let summary of toeSummary;even as eve; last as last; let i = index">
                                                        <td>{{i + 1}}</td>
                                                        <td>{{summary.category}}</td>
                                                        <td scope="col" class="align-middle text-center" colspan="1" *ngFor="let item of [].constructor(detailSummary.vendor_total_score_by_category.length * 2); 
                                                            even as eve; let j = index;">
                                                            {{eve ? detailSummary.vendor_total_score_by_category[(j / 2)].total_actual_score : detailSummary.vendor_total_score_by_category[(j / 2) - 0.5].total_weightage_score}}
                                                        </td> -->


                                                        <!-- <ng-container *ngFor="let vendor of toeSummaryVendorList">
                                                            [ngStyle]="{'background-color': eve ? detail.vendor_score[(j / 2)]?.top_actual_score ? 'green' : (detail.vendor_score[(j / 2)]?.low_actual_score ? 'red' : '#FFBF00') : ''} "
                                                            <td >{{getColor(summary,vendor.vendor)}}{{getVendorAsCount(summary.toes,vendor.vendor)}}</td>
                                                            <td>{{getVendorWSCount(summary.toes,vendor.vendor)}}</td>
                                                        </ng-container> -->
                                                    <!-- </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <ng-container *ngFor="let vendor of toeSummaryVendorList">
                                                            <td>{{getTotalVendorAsCount(vendor.vendor)}}</td>
                                                            <td>{{getTotalVendorWSCount(vendor.vendor)}}</td>
                                                        </ng-container>
                                                    </tr>
                                                </tfoot> -->
                                            </table>
                                        </div>
                                    </div>
                                    <!-- detail table -->
                                    <div class="container" *ngIf="detailedSummary">
                                        <div class="custom-table table-responsive" *ngFor="let detailSummary of toeSummary;">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col" colspan="3">{{detailSummary.category}}</th>
                                                        <th scope="col" colspan="2" class="align-middle text-center" *ngFor="let vendors of toeSummaryVendorList">{{vendors.vendor}}</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col"></th>
                                                        <th scope="col">Scope</th>
                                                        <th scope="col">Criticality</th>
                                                        <th scope="col">Weightage</th>
                                                        <th scope="col" colspan="1" class="align-middle text-center" *ngFor="let item of [].constructor(toeSummaryVendorList.length * 2); even as eve">{{eve ? 'Actual' : 'Weighted'}}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr  *ngFor="let detail of detailSummary.toes;even as eve; let i = index">
                                                        <td>{{i + 1}}</td>
                                                        <td>{{detail.toe}}</td>
                                                        <td class="align-middle text-center"><span>
                                                            <mat-icon *ngIf="detail.criticality == 'Nice to have'" [matTooltip]="'Nice to have'">star_half</mat-icon>
                                                        	<mat-icon *ngIf="detail.criticality == 'Mandatory'" [matTooltip]="'Mandatory'">warning</mat-icon></span></td>
                                                        <td class="align-middle text-center">{{detail.weightage}} %</td>
                                                        <td scope="col" colspan="1" class="align-middle text-center" *ngFor="let item of [].constructor(detail.vendors.length * 2); 
                                                        even as eve; let j = index;" [ngStyle]="{'background-color': eve ? detail.vendors[(j / 2)]?.top_actual_score ? 'green' : (detail.vendors[(j / 2)]?.low_actual_score ? 'red' : '#FFBF00') : ''} ">
                                                        {{eve ? detail.vendors[(j / 2)].AS : detail.vendors[(j / 2) - 0.5].WS}}
                                                        </td>
                                                    </tr>

                                                    <!-- *ngFor="let overAllSum of overallSumaryData;even as eve; last as last; let i = index" -->
                                                    <!-- <tr  *ngFor="let detail of detailSummary.toes;even as eve; let i = index">
                                                        <td>{{i + 1}}</td>
                                                        <td>{{detail?.toe}}</td>
                                                        <td class="align-middle text-center">{{detail?.weightage}} %</td>
                                                        <ng-container *ngFor="let vendor of detail?.vendors">
                                                            <td>{{vendor?.AS}}</td>
                                                            <td>{{vendor?.WS}}</td>
                                                        </ng-container>
                                                    </tr> -->
                                                </tbody>
                                                <tfoot>
                                                    <tr >
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td class="align-middle text-center">100 %</td>
                                                        <ng-container *ngFor="let vendor of toeSummaryVendorList">
                                                            <td>{{getTotalASCountByCategory(vendor.vendor,detailSummary.toes)}}</td>
                                                            <td>{{getTotalWSCountByCategory(vendor.vendor,detailSummary.toes)}}</td>
                                                        </ng-container>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!toeSummary">
                                    <div class="text-center">
                                        No Data Found.
                                    </div>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
            </div>
        </div>
    </div>
</div>